<template>
    <div>
        <b-modal id="accessEdit" title="Изменить данные доступа" size="sm" :no-close-on-backdrop="true" hide-footer no-enforce-focus @show="opened">
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Наименование</label>
                        <b-form-input v-model="item.name" id="basicInput" placeholder="Наименование"/>
                    </b-form-group>    
                </b-col>
                <b-col>
                   <b-form-checkbox v-model="item.is_active" class="custom-control-primary">
                    {{ item.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editAccess">
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    props: ['id'],
    components: {
        vSelect,
        ToastificationContent
    },
    data(){
        return {
            item: {
                name: null,
            },
            submitButtonDisabled: false,
        }
    },
    methods: {
        editAccess(){
            this.submitButtonDisabled = true
            this.$http
                .patch(`access/${this.id}`, this.item)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.closeModal()
                    // this.$bvModal.hide('accessEdit')
                    this.$emit('refresh')
                })
                .catch(err=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                }).finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.$http
                .get(`access/${this.id}/edit`)
                .then(res => {
                    this.item = res.data
            })
        },
        closeModal(){
            this.$bvModal.hide('accessEdit')
        }
    },
}
</script>